/* Global Resetting */
html,
body {
	width: 100%;
	float: left;
	height: 100%;
	display: flex;
	margin: 0;
	padding: 0;
	background-color: #e8edf4;
	transition: all 350ms;
	//font-family: 'Exo', sans-serif !important;
	// font-family: 'IBM Plex Sans', sans-serif !important;
	font-family: "Poppins", sans-serif !important;
}

@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "~@angular/material/theming";
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
	$font-family: "Poppins, sand-serif !important",
);
// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat-base-typography($custom-typography);

// Override typography for a specific Angular Material components.
@include mat-checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);
// Override the typography in the core CSS.
@include mat-core($custom-typography);

.mat-typography h1 {
	font-weight: normal !important;
}

.mat-sort-header-content {
	font-weight: 500 !important;
	color: #383f67;
}

.mat-badge:not(.mat-badge-accent) .mat-badge-content {
	color: white;
	background: #6074e2 !important;

	font {
		color: white;
	}
}

.mat-checkbox-label {
	font-weight: normal !important;
}

.mat-chip.mat-standard-chip {
	color: #383f67 !important;
	font-weight: normal !important;
}

/* Border */

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
	color: #6074e2 !important;
}

.mat-checkbox-frame {
	border-color: rgb(136 146 161) !important;
}

.mat-tab-label,
.mat-tab-link {
	font-size: 13px !important;
}

.mat-menu-panel {
	min-height: 48px !important;
}

.mat-datepicker-content .mat-datepicker-close-button {
	position: absolute !important;
}

.mat-form-field-appearance-outline
	.mat-form-field-outline-thick
	.mat-form-field-outline-start,
.mat-form-field-appearance-outline
	.mat-form-field-outline-thick
	.mat-form-field-outline-end,
.mat-form-field-appearance-outline
	.mat-form-field-outline-thick
	.mat-form-field-outline-gap {
	border-width: 1px !important;
	border-color: transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-end {
	border-width: 1px !important;
	border-color: transparent !important;
	min-width: 5px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
	color: #c3cddc !important;
	background: #f3f6f9;
	border-radius: 5px;
}

.mat-select-value {
	color: rgb(133 135 166);
}

fieldset {
	border-color: #e8edf333;
}

.mat-tree-node,
.mat-nested-tree-node {
	font-size: 12px !important;
	min-height: 32px !important;
}

.cdk-overlay-container {
	position: fixed;
	z-index: 99999 !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
	.mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
	.mat-input-server:focus
	+ .mat-form-field-label-wrapper
	.mat-form-field-label {
	transform: translateX(-9px) translateY(-2.8em) scale(0.9) !important;
	width: 100% !important;
	color: #8687a7 !important;
}

.mat-form-field.mat-focused {
	*:not(app-export-version-ball *) {
		color: #6074e2 !important;
	}

	app-reaction-icon i {
		color: #fff !important;
	}

	.mat-form-field-label {
		color: #6074e2 !important;
	}

	.mat-form-field-ripple {
		background-color: #6074e2 !important;
	}

	.mat-form-field-required-marker {
		color: #6074e2 !important;
	}

	.mat-input-element {
		color: #6074e2 !important;
	}
}

.mat-form-field {
	font-size: 12px !important;
	padding-bottom: 0px !important;
	padding-top: 10px !important;
	overflow-x: hidden;
}

app-extract-product-data .ag-popup-editor {
	//min-width: 496px;
	// top: 50% !important;
	//left: 50% !important;
	//transform: translate(-50%, -50%) !important;
	//max-height: 75% !important;
	//overflow: auto !important;
}

.mat-form-field-label {
	overflow: visible !important;
	//line-height: 1em !important;
}

.mat-form-field-label-wrapper {
	overflow: visible !important;
}

input {
	line-height: 1em !important;
}

.mat-error {
	color: #f44336;
	// background: #db3c3021;
	padding: 5px 10px;
	border-radius: 4px;
	margin-bottom: 15px;
}

.reaction-button button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
}

span.mat-button-toggle-label-content {
	padding: 0px !important;
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
	display: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
	padding: 0px !important;
}

.mat-optgroup-label {
	display: flex !important;
	font-weight: 500 !important;
	color: #4c5376 !important;
	align-items: center;
}

// Account Management / Clients
.notes-box .mat-form-field-appearance-outline .mat-form-field-outline {
	background-color: #ffffffa8 !important;
}

.mat-option {
	color: #8487a6 !important;
}

html {
	font-size: 1em;
}

html.translated_ltr {
	color: red;
}

p {
	font-size: 13px;
}

* {
	font-size: 1em;
	/* reset font-sizes to 1em == 10px */
	padding: 0;
	margin: 0;
	outline: none !important;
	text-decoration: none;
	-webkit-overflow-scrolling: touch;
	box-sizing: border-box;
}

img {
	max-width: 100%;
	max-height: auto;
}

.pointer {
	cursor: pointer;
}

// General
.tac {
	text-align: center;
}

.tar {
	text-align: right;
}

.tal {
	text-align: left;
}

.clear {
	clear: both;
}

.oh {
	overflow: hidden;
}

.devShow {
	box-shadow: 0px 0px 0px 1px #000;
}

font {
	font-family: inherit !important;
	color: inherit;
}

.text-dark {
	color: #363f67 !important;
}

.bold {
	font-weight: normal !important;
}

// Text Highlighting
::-moz-selection {
	background: #b28abf;
	color: #fff !important;
}

::selection {
	background: #373f67;
	color: #fff !important;
}

.ag-header-cell {
	padding-right: 6px !important;
}
