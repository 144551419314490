// Inline block
.dib {
	display: inline-block;
	vertical-align: middle;
}

// Flex columns and rows
.flex {
	display: flex;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
}

.col {
	display: flex;
	flex-direction: column;
}

.col-reverse {
	display: flex;
	flex-direction: column-reverse;
}

.row-reverse {
	display: flex;
	flex-direction: row-reverse;
}

.full-height {
	height: 100%;
}

// Scrolling
.scroll-y {
	overflow-x: hidden;
	overflow-y: auto;
}

.scroll-x {
	overflow-y: hidden;
	overflow-x: auto;

	&:not(.chip-bar)::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px #5465bdcc;
		//border-left: solid 0px transparent !important;
		border-top: solid 8px transparent;
	}

	&:not(.chip-bar)::-webkit-scrollbar {
		width: 11px;
		height: 11px;
	}
}

// Columns
$columns: 12;

@for $i from 1 through $columns {
	.col-#{$i} {
		display: flex;
		position: relative;
		width: percentage($i/$columns);
	}
}

@for $i from 1 through $columns {
	.flex-#{$i} {
		flex: $i;
	}
}

// Margins and padding
@for $i from 1 through 10 {
	$px: #{(5 * $i)}px;

	.p#{(5*$i)} {
		padding: $px;
	}

	.pl#{(5*$i)} {
		padding-left: $px;
	}

	.pr#{(5*$i)} {
		padding-right: $px;
	}

	.pt#{(5*$i)} {
		padding-top: $px;
	}

	.pb#{(5*$i)} {
		padding-bottom: $px;
	}

	.m#{(5*$i)} {
		margin: $px;
	}

	.ml#{(5*$i)} {
		margin-left: $px;
	}

	.mr#{(5*$i)} {
		margin-right: $px;
	}

	.mt#{(5*$i)} {
		margin-top: $px;
	}

	.mb#{(5*$i)} {
		margin-bottom: $px;
	}
}

// Simple CSS Grid (apply this class to the parent for a simple responsive grid)
.grid {
	display: grid;
	grid: auto-flow dense auto / repeat(auto-fit, minmax(300px, 1fr));
}

// Wrapping
.stack {
	min-width: 300px;
}

@for $i from 2 through 20 {
	.stack#{(24*$i)} {
		min-width: #{(24 * $i)}px;
	}

	.stack#{(25*$i)} {
		min-width: #{(25 * $i)}px;
	}

	.grid#{(24*$i)} {
		height: 100%;
		display: grid;
		grid: auto-flow dense auto / repeat(auto-fit, minmax(#{(24 * $i)}px, 1fr));
	}

	.grid#{(25*$i)} {
		height: 100%;
		display: grid;
		grid: auto-flow dense auto / repeat(auto-fit, minmax(#{(24 * $i)}px, 1fr));
	}
}

// Spacing
.space-around {
	justify-content: space-around;
	align-content: space-around;
}

.space-between {
	justify-content: space-between;
	align-content: space-between;
}

.space-evenly {
	justify-content: space-evenly;
	align-content: space-evenly;
}

// Alignment
.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: flex-end;
}

.justify-start {
	justify-content: flex-start;
}

.align-start {
	align-items: flex-start;
}

.align-center {
	align-items: center;
}

.align-end {
	align-items: flex-end;
}

// Wrapping
.flex-nowrap {
	flex-wrap: nowrap;
}

// Center column
.inner {
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;
	position: relative;

	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

// Icons sizes
$maxIconSize: 200;
$smallest: 8;
$multiplesOfEight: 33;

@for $i from 1 through $maxIconSize {
	@if $i < $multiplesOfEight and $i>$smallest {
		.iconSize#{$i} {
			font-size: #{$i}px;
			width: #{$i}px !important;
			height: #{$i}px !important;
			line-height: #{$i}px;
		}
	} @else {
		@if $i % $smallest==0 {
			.iconSize#{$i} {
				font-size: #{$i}px;
				width: #{$i}px !important;
				height: #{$i}px !important;
				line-height: #{$i}px;
			}
		}
	}
}

// Mobile - stack sizes to full width
@media screen and (max-width: 400px) {
	*[class*="stack"] {
		min-width: 100% !important;
	}

	.grid {
		grid: auto-flow dense auto/repeat(auto-fit, minmax(200px, 1fr));
	}
}

.headerMenuClass {
	display: flex;
	justify-content: flex-end;
}
