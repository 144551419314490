@import "scss/reset";
@import "scss/scrollbar";
@import "scss/mixins";
@import "scss/grid";
@import "scss/layout";
@import "scss/responsive";

@import "hark-theme";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine/sass/_ag-theme-alpine-mixin.scss";

.ag-theme-alpine {
	@include ag-theme-alpine(
		(
			// use theme parameters where possible
			background-color: #ffffff,
			border-radius: 5px,
			data-color: #8687a7,
			header-foreground-color: #000000,
			header-height: 34px,
			input-border-color: #d8ddde,
			checkbox-checked-color: #8f6fb1,
			row-border-color: #d8ddde,
			odd-row-background-color: #ffffff,
			header-background-color: #eff3f4,
			font-family: ("Roboto", sans-serif),
			row-hover-color: #f2f3f5,
			font-size: 12px,
			range-selection-border-color: #8f6fb1,
			// selected-row-background-color: #e9eef3,
			selected-row-background-color: #eee4f0,
			border-color: #d8ddde,
			cell-horizontal-border: solid #d8ddde,
			// borders: false
		)
	);
}

.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
	opacity: 0.5 !important;
}

.mat-tab-label,
// >>>>>>>>>>>>>> TOP LEVEL AND BASIC STRUCTURE
.mat-tab-link {
	//color: #8687a7 !important;
	color: #8687a7 !important;
	opacity: 1 !important;
}

// .full-height {
//   height: 100%;
//   margin: 0;
//   padding: 0px;
// }

.full-width {
	width: 100%;
	margin: 0;
	padding: 0px;
}

.full-screen {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0px;
}

/**
    * TEXT
    **/

mat-line {
	text-overflow: ellipsis;
}

.nowrap {
	white-space: nowrap;
}

.text-medium {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 1em;
	line-height: 1.2em;
	font-weight: normal;
}

.text-small {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	font-size: 0.6875em;
	line-height: 1.2em;
	font-weight: normal;
}

.text-subtle {
	font-size: 12px;
	color: #8687a7;
	font-weight: normal;
	line-height: 1.3em;
}

.text-more-subtle {
	font-size: 0.675em;
	color: #8687a7;
	font-weight: normal;
	line-height: 1.2em;
}

.disable-selection,
.disable-selection * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline: none;
	// border: 0;
}

.disable-element-selection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline: none;
	border: 0;
}

.disable-pointer-below * {
	pointer-events: none;
}

.ng-draggable {
	cursor: move;
}

.largeIcon // >>>>>>>>>>>> CURSORS AND ICONS
{
	font-size: 40px;
	width: 40px !important;
	height: 40px !important;
	line-height: 40px !important;
}

.largerIcon {
	font-size: 48px;
	width: 48px !important;
	height: 48px !important;
	line-height: 48px !important;
}

.fiftySixIcon {
	font-size: 56px;
	width: 56px !important;
	height: 56px !important;
	line-height: 56px !important;
}

.xlargeIcon {
	font-size: 64px;
	width: 64px !important;
	height: 64px !important;
}

.massiveIcon {
	font-size: 128px;
	width: 128px !important;
	height: 128px !important;
}

.largeishIcon {
	font-size: 32px;
	height: 32px !important;
	width: 32px !important;
}

.slightlyLargeIcon {
	font-size: 28px;
	height: 28px !important;
	width: 28px !important;
}

.mediumIcon {
	font-size: 24px;
	height: 24px !important;
	width: 24px !important;
}

.mediumishIcon {
	font-size: 20px;
	height: 20px !important;
	width: 20px !important;
}

.smallishIcon {
	font-size: 19px;
	height: 19px !important;
	width: 19px !important;
}

.smallIcon {
	font-size: 16px;
	height: 16px !important;
	width: 16px !important;
}

.quiteSmallIcon {
	font-size: 12px;
	height: 12px !important;
	width: 12px !important;
}

.ratherSmallIcon {
	font-size: 12px;
	height: 11px !important;
	width: 11px !important;
}

.verySmallIcon {
	font-size: 8px;
	height: 8px !important;
	width: 8px !important;
}

.smallButtonIcon {
	font-size: 18px;
}

.infoIcon {
	color: #b289bf !important;
	cursor: pointer;
	cursor: hand;
}

.smallInfoIcon {
	@extend .smallIcon;
	@extend .infoIcon;
}

.centered-small-fab-button-icon {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 20px !important;
	width: 20px !important;
	line-height: 20px !important;
	font-size: 16px;
	text-align: center;
	vertical-align: middle;
}

.centered-smaller-fab-button-icon {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 16px !important;
	width: 16px !important;
	line-height: 16px !important;
	font-size: 12px;
	text-align: center;
	vertical-align: middle;
}

mat-sidenav {
	z-index: 100;
	box-shadow: 0px 0px 30px 0px rgba(133, 145, 171, 0.18);
}

// #footer * {
//   // font-size: 16px;
//   color: white;
//   overflow: hidden;
//   font-weight: normal;
// }

// #footer mat-icon {
//   font-size: 20px;
//   font-weight: 600;
// }

.text-list-title {
	font-weight: 400;
}

.text-subsection-title {
	font-size: 20px;
	font-weight: 400;
	color: #8687a7;
}

.text-subsection-subtitle {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.text-card-item-title {
	font-size: 12px !important;
	font-weight: 400 !important;
}

.text-center {
	text-align: center;
}

.router-outlet // >>>>>>>>>>>>> Navigational Structure
{
	width: 0px;
	height: 0px;
}

#header {
	overflow: visible !important;
}

#header .mat-toolbar-row {
	height: 100%;
	font-weight: 600; // padding: 8px 8px 0px 8px;
}

#header .mat-toolbar {
	min-height: 48px;
	padding: 15px 16px;
	box-shadow: 0px 0px 30px 0px rgba(133, 145, 171, 0.18);
}

#main-content // Area beneath the top application bar , and above the footer.
{
	// Main content height is 100% minus pixed header / footer sizes.
	/* height: calc(100% - 118px);*/
	height: 100%;
}

// #footer {
//   width: 100%;
//   text-align: center;
//   z-index: 999;
//   border-bottom: 0px !important; // height: 60px;
// }

.mat-sidenav-content {
	height: 100%; // Content within is by itself should fill full height.
	width: 100%;
}

// >>>>>>>>>>>>> SECTIONS ADD THEM HERE! <<<<<<<<<<<<<<<<<<<<<
// *** If you having issues with FULL HEIGHT LISTS, try below....
// *** Or other screen height / centering issues....
// For safri to correctly calculate height when set to 100%
// all objects up to root must have height explicitly set.
// So we'll set the strcuture divs height here.
// Also note you'll get incorrect heights if the 100% shares its parent with a sibling
// So Menu 40px + content 100% = too big. So need to calculate height without nav bar.
// We need to do this for all first level sub menus, eg Access Control -> workgroups , publisihng -> datasets ,publishing -> exports etc..
// ROUTER SECTIONS LEVEL 1 - Navigated via Application Bar Side Menu.
// Included in the #main-content, they should take full height as Application bar and footer
// have been accounted for.

app-access-control,
app-auth-token,
app-account-management,
app-client-library-version-management,
app-configuration,
app-dev-tools,
app-client-tools,
app-login-selection,
app-ahub,
app-login-complete,
app-logout,
app-publishing,
app-register,
app-verification-email-sent,
app-verify,
app-return-to-app,
app-usage,
app-welcome,
app-my-account,
app-library,
app-library-config {
	height: 100%; // Content within is by itself should fill full height.
	width: 100%;
}

#level1-container // ROUTER SECTION LEVEL 1 - CONTAINER
{
	// All top level 1 sections should have a div with this tag, which gives
	// flex container layout for level 1 navigation and content.
	height: 100%;
	width: 100%;
	padding: 0px;
	padding: 0;
	//overflow: hidden!important;
	z-index: 49;
}

#level1-header // ROUTER SECTION LEVEL 1 - NAVIGATION
{
	// Holds the first ( Horizontal ) level of navigation.
	position: relative; // req. Shadow.
	width: 100%;
	z-index: 46;
}

#level1-content // ROUTER SECTION LEVEL 1 - CONTENT
{
	// Content that chnages based on level 1 navigation, router-outlet 2
	// Level 2 section within.
	height: 100%;
	width: 100%;
	z-index: 43;
	display: flex;
	overflow: hidden;
}

#level1-content-double-header {
	height: calc(100% - 88px);
	width: 100%;
	z-index: 43;
}

@media screen and (min-width: 1349px) {
	// ROUTER SECTION LEVEL 2 - ANY TOP BLUE NAVIGATION - ADD YOURS HERE !!!!
	app-welcome-home,
	app-welcome-software,
	app-user-details,
	app-my-workgroups,
	app-work-groups,
	app-products,
	app-product-extract-definitions,
	app-extract-assets-tab,
	app-extract-data-in-out-tab,
	app-product-extract-data,
	app-datasets,
	app-exporters,
	app-exports,
	app-resource-packs,
	app-distribution-groups,
	app-usage-quotas,
	app-integration,
	app-client-config-tab,
	app-clients,
	app-users-admin,
	app-users-distribution,
	app-versions,
	app-debug,
	app-system-databases,
	app-system-control,
	app-space-1,
	app-space-2,
	app-space-3,
	app-space-4,
	app-space-5 {
		height: 100%;
		flex-grow: 5 !important;
		flex-shrink: 5 !important;
		flex-basis: auto !important;
		display: flex; // Set all direct children to NOT flex by default.

		> * {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: auto;
		}
	}
}

// All sub section container navigation should have this style applied.
app-distribution-groups nav,
app-work-groups nav,
app-products nav,
app-product-extract-definitions nav,
app-extract-assets-tab nav,
app-extract-data-in-out-tab nav,
app-product-extract-data nav,
app-exports nav,
app-datasets nav,
app-exporters nav,
app-resource-packs nav,
app-welcome nav,
app-my-account nav,
app-clients nav,
app-users-admin nav,
app-users-distribution nav,
app-versions nav {
	border-bottom: 0px !important;
}

// All sub section title tool-bars should have this style applied.
app-distribution-groups mat-toolbar,
app-work-groups mat-toolbar,
app-products mat-toolbar,
app-product-extract-definitions mat-toolbar,
app-extract-assets-tab mat-toolbar,
app-extract-data-in-out-tab mat-toolbar,
app-product-extract-data mat-toolbar,
app-exports mat-toolbar,
app-datasets mat-toolbar,
app-exporters mat-toolbar,
app-resource-packs mat-toolbar,
app-welcome mat-toolbar,
app-my-account mat-toolbar,
app-clients mat-toolbar,
app-users-admin mat-toolbar,
app-users-distribution mat-toolbar,
app-versions mat-toolbar {
	background-color: whitesmoke;
	padding-left: 30px;
	padding-right: 30px;
}

// SECTION LEVEL 2 - Content tags - ADD YOURS HERE !!!!
app-work-group,
app-products,
app-product-extract-definition,
app-product-extract,
app-dataset,
app-exporter,
app-resource-pack,
app-export,
app-distribution-group,
app-client,
app-user,
app-version {
	height: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	flex-direction: column;
	display: flex;
}

// >>>> SECTION LEVEL 3 - TABS - ADD YOURS HERE !!!!
app-work-group-details-tab,
app-work-group-users-tab,
app-version-details-tab,
app-version-creation-tab,
app-version-upgrading-tab,
app-version-clients-tab,
app-distribution-group-details-tab,
app-distribution-group-users-tab,
app-distribution-group-distributions-tab,
app-distribution-group-filters-tab,
app-export-details-tab,
app-export-distributions-tab,
//app-export-legacy-ref-tab,
app-dataset-details-tab,
app-exporter-details-tab,
app-exporter-destinations-tab,
app-exporter-mapping-tab,
app-exporter-resources-tab,
app-exporter-delivery-tab,
// app-resource-pack-details-tab,
app-resource-pack-content-tab,
app-client-details-tab,
app-client-usage-tab,
// app-client-configuration-tab,
app-client-config-tab,
app-user-details-tab,
app-user-export-distributions-tab,
app-classification,
app-properties,
app-product-extract-definition,
app-product-extract {
	height: 100%;
	flex-grow: 5 !important;
	flex-shrink: 5 !important;
	flex-basis: auto !important;
	display: flex; // Set all direct children to NOT flex by default.

	> * {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: auto;
	}
}

.nav-section // Primary section navigation
{
	border-bottom: 0px;
}

#level2-item-selection // SECTION LEVEL 2 - ITEM SELECTION - Left side, items selection, normally a list.
{
	display: flex;
	padding: 0px 20px 10px 20px;
	position: relative; // req. Shadow.
	z-index: 34;
	flex-grow: 0;
	flex-shrink: 0;
	box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
	border-radius: 8px;
	background-color: #ffffff !important;
	margin-right: 20px;
	flex-basis: 320px;
	height: 100%;
	/*
      -webkit-box-shadow: 0 8px 10px 5px rgba(0, 0, 0, .2);
      -moz-box-shadow: 0 8px 10px 5px rgba(0, 0, 0, .2);
      box-shadow: 0 8px 10px 5px rgba(0, 0, 0, .2);
      */
}

#level2-navigation // SECTION LEVEL 2 - Horizontal Heading and navigation bar ( tabs ).
{
	position: relative; // req. Shadow.
	//height: 96px; // 48px header + 48px secondary level navigation bar.
	z-index: 36; //nav shadow.
}

#level2-navigation-no-title // SECTION LEVEL 2 - no title - navigation bar only -( tabs ).
{
	position: relative; // req. Shadow.
	height: 48px; //  48px secondary level navigation bar.
	z-index: 36 !important; //nav shadow.
}

#level2-content // SECTION LEVEL 2 - Content  below , including router outlet for level 3 content ( Tabs ).
{
	height: 100%;
	z-index: 33;
}

#level2-content-no-title // SECTION LEVEL 2 - Content  below , including router outlet for level 3 content ( Tabs ).
{
	z-index: 33;
	display: flex;
	height: 100%;

	#level2-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}

#level3-navigation // SECTION LEVEL 3 - TABS
{
	position: relative; // req. Shadow.
	height: 48px; // 48px header + 40px secondary level navigation bar.
	z-index: 26; //nav shadow.
	font-weight: 500 !important;
	width: 100%;
	font-size: 1.2em !important;
	letter-spacing: -1px;
	color: #121331;
}

#level3-content // SECTION LEVEL 2 - Content  below , including router outlet for level 3 content ( Tabs ).
{
	height: calc(100% - 48px);
	z-index: 23;
	overflow: hidden !important;
}

#level3-item-selection // SECTION LEVEL 3 - ITEM SELECTION - Left side, items selection, normally a list.
{
	position: relative; // req. Shadow.
	// min-width: 280px;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: 30%;
	height: 100%;
	overflow: hidden;
}

#level3-item-view // SECTION LEVEL 3 - ITEM VIEW, area for display of items made in selection.
{
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 70%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
}

#level4-navigation // SECTION LEVEL 4 - TABS
{
	position: relative; // req. Shadow.
	height: 96px; // 48px header + 40px secondary level navigation bar.
	z-index: 16;
}

#level4-content // SECTION LEVEL 4 - Content  below , including router outlet for level 3 content ( Tabs ).
{
	height: calc(100% - 96px);
	z-index: 13;
}

#categoryTabs .mat-ink-bar {
	background-color: #40c2cc !important;
	height: 5px !important;
	z-index: 100;
}

app-login-selection,
app-ahub,
app-login-complete,
app-register,
app-verification-email-sent,
app-verify {
	top: 0;
	left: 0px;
	right: 0px;
	height: 100%;
	background-position: center, center;
	background-repeat: no-repeat;
}

app-export-index-item,
// >>>>  Any components used for injecting into navigation lists ( nav item list redenerers )
app-client-index-item,
app-user-index-item,
app-distribution-group-index-item,
app-distribution-group-distribution-index,
app-export-distribution-index,
app-extract-index-list-item,
app-extract-allocations-item,
app-resource-pack-index-item,
app-value-rule-index-item,
app-export-distribution-index-item,
app-client-file-change-set-index-item {
	width: 100%;
}

// Styles for nested tabs
app-category-rules-tab,
app-category-products-tab,
app-category-sorting-tab {
	display: flex;
	flex: 1;
	width: 100%;
}

.flex-container // >>>>>>>>> GENERAL FLEXBOX STYLES <<<<<<<<<<<<<<<<<
{
	display: flex;
	padding: 0px !important; // Set all direct children to NOT flex by default.

	> * {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: auto;
	}
}

.stretchy // If marked as strechy, override default and FLEX !
{
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

.column {
	flex-direction: column;
}

.large-icon-unit,
.busy-spinner-unit // Flex box geared to fit large icon content.
{
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 44px;
	max-width: 44px;
	overflow: visible;
	padding: 2px;
}

.medium-icon-unit // Flex box geared to fit medium icon content.
{
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 28px;
	max-width: 28px;
	overflow: visible;
	padding: 2px;
}

// Flex abbreviation (e.g. flex 1 1 0) seperated to allow for IE bug
.one-unit // Flex style two part unit width.
{
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0%;
}

.two-unit // Flex style three part unit width.
{
	flex-grow: 2;
	flex-shrink: 2;
	flex-basis: 0%;
}

.three-unit // Flex style four part unit width.
{
	flex-grow: 3;
	flex-shrink: 3;
	flex-basis: 0%;
}

.four-unit // Flex style four part unit width.
{
	flex-grow: 4;
	flex-shrink: 4;
	flex-basis: 0%;
}

.five-unit {
	flex-grow: 5;
	flex-shrink: 5;
	flex-basis: 0%;
}

.six-unit {
	flex-grow: 6;
	flex-shrink: 6;
	flex-basis: 0%;
}

.seven-unit {
	flex-grow: 7;
	flex-shrink: 7;
	flex-basis: 0%;
}

.scroll // >>>>> SCROLLING DEFINITIONS
{
	// both ways.
	overflow: auto;
}

.scroll-vertical {
	// Vertical
	overflow-y: auto;
	overflow-x: hidden;
}

.scroll-horiz {
	// horz only.
	overflow-x: auto;
	overflow-y: hidden;
}

.no-scroll-hidden {
	// No scrolling , hide overflow.
	//overflow: hidden !important;
}

.no-scroll-visible {
	overflow: visible !important;
}

.fb-left // >>>>>>>>>>>>>>>>> FLEX BOX ALIGNMENT
{
	justify-content: flex-start;
}

.fb-right {
	justify-content: flex-end;
}

.fb-center {
	justify-content: center;
	align-items: center;
}

.fb-justify {
	justify-content: space-between;
	align-items: center;
}

.fb-valign-container {
	display: table;
	overflow: hidden;
}

.fb-valign-container .valign {
	display: table-cell;
	vertical-align: middle;
}

.padding-u // Padding to left right and bottom, u shapped.
{
	margin-left: 8px;
	margin-right: 8px;
	margin-bottom: 8px;
}

mat-grid-list // >>>>>>>>>>>>> GENERAL COMPONENT STYLES <<<<<<<<<<<<<<<<<<<<<
{
	margin-bottom: 30px;
	margin-top: 30px;
}

mat-grid-tile {
	border-width: 1px;
	border-style: solid;
}

.action-buttons // Action buttons - under horizontal line normally in cards etc.
{
	display: flex;
	width: calc(100% - 16px);
	justify-content: flex-end;
	//min-height: 36px;
	margin: 8px;
}

.center-action-buttons {
	display: flex;
	width: calc(100% - 16px);
	justify-content: center;
	height: 36px;
	margin: 8px;
}

hr // <hr/> quick horizontal divider style useful to seperate card actions.
{
	color: lightgrey;
	background-color: lightgrey;
	border: none;
	height: 1px;
	width: 100%;
}

.disabled {
	opacity: 0.8;
	pointer-events: none;

	> * {
		opacity: 0.8;
		pointer-events: none;
	}
}

.enabled {
	opacity: 1;
	pointer-events: all;

	> * {
		opacity: 1;
		pointer-events: all;
	}
}

mat-toolbar,
.mat-tab-nav-bar,
.mat-toolbar-row {
	margin: 0;
}

mat-list,
mat-nav-list {
	// all lists should butt up to thier titles.
	padding-top: 0px !important;
	z-index: 5;
}

// .mat-tab-links // Tab links , slightly narrow. remove for 48px default.
// {
//   height: 40px;
// }
app-list-search-header {
	z-index: 10 !important;
}

mat-list-item {
	z-index: 5;
	height: fit-content;
}

mat-list.shadow {
	z-index: 10;
	position: relative;
	box-shadow: none;
}

mat-nav-list.list-with-header {
	//height: calc(100% - 96px); // 48 for the header, 48 for the padding.
	height: calc(63vh - 96px);
	padding-bottom: 48px;
}

.list-with-header {
	height: calc(100% - 96px); // 48 for the header, 48 for the padding.
	padding-bottom: 48px;
}

.dialog-container mat-list,
mat-card mat-list {
	// When a list is on a card or in a dialog box which has a white background
	// we'll change the list backgroud to off white.
	background-color: whitesmoke;
}

mat-expansion-panel {
	position: relative;
	-webkit-box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.3) !important;
	-moz-box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.3) !important;
	box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.3) !important;
	background-color: white;
}

.mat-expansion-panel-body {
	background: #e8edf361;
	border-radius: 8px;
}

.mat-expansion-panel-header.mat-expanded {
	height: 64px;
	border-radius: 4px 4px 0px 0px;
}

.mat-expansion-panel-header:before {
	content: "";
	display: block;
	position: absolute;
	top: 0px;
	width: 100%;
	background-color: #fff;
	height: 100%;
	opacity: 0.07;
	transform: skewX(45deg) translateX(54%);
}

mat-dialog-container // Top level dialog containers ( should'nt scroll ).
{
	overflow: hidden !important;
	min-width: 30em;
}

// Used to allow opened dialogs to take most of the screen space
.full-screen-dialog {
	max-height: 95vh !important;
	max-width: 95vw !important;
}

// Used to allow opened dialogs to take half of the screen space
.half-screen-dialog {
	max-height: 50vh !important;
	max-width: 50vw !important;
}

mat-list-item.add-list-item {
	background-color: white;
}

mat-radio-group {
	margin-left: 16px;
	margin-right: 16px;
}

mat-radio-button {
	vertical-align: middle;
}

.vertical-chips .mat-chip-list-wrapper {
	flex-direction: column;
	height: 100%;
}

mat-list-item.list-item-72 {
	height: 72px !important;
}

mat-list-item.list-item-64 {
	height: 64px !important;
}

.list-item-64 .mat-list-item,
.list-item-64 .mat-list-item-content {
	height: 64px !important;
}

mat-list-item.list-item-48 {
	height: 48px !important;
}

.list-item-36 .mat-list-item,
.list-item-36 .mat-list-item-content {
	height: 36px !important;
}

.list-title-bar {
	width: 100%;
	z-index: 10;
}

.primary-title-bar {
	width: 100%;
}

.accent-title-bar {
	width: 100%;
}

app-form-list,
app-index-list {
	// For safri to correctly calculate height when set to 100%
	// all objects up to root must have height explicitly set.
	// For lists which we want to be the height of the screen , we need this..
	height: 100%;
	overflow: hidden;
}

// Required to nicely contain pages from the knowledge base in an iframe in a dialog
app-knowledge-base-dialog {
	display: flex;
}

.list-container {
	// Its behind the scrollable list content.
	// Best same colour background as list items.
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: white;
	-webkit-transform: translate3d(
		0,
		0,
		0
	); // workaround for iPad Safari FAB buttons under text in list.
	transform: translate3d(0, 0, 0);
}

/* When in a card, List items are grey, background should match */

mat-card .list-container {
	background-color: whitesmoke !important;
}

.list-container-below-header {
	// Its behind the scrollable list content.
	// Best same colour background as list items.
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	-webkit-transform: translate3d(
		0,
		0,
		0
	); // workaround for iPad Safari FAB buttons under text in list.
	transform: translate3d(0, 0, 0);
}

.list-container-inf,
.list-container-inf .list-container {
	// When the list container is for infinite list, different background
	// to highlight gaps between list items.
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: whitesmoke;
}

.mat-tree .selected-item {
	font-weight: 600;
}

.selected-item-light {
	background-color: #e7edf1;
}

.tooltip {
	position: relative;
	display: inline-block;
	overflow: visible;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #5c5a5a;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 10px;
	position: absolute;
	top: -30px;
	left: -60px;
	z-index: 9999;
	box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

button.mat-mini-fab {
	box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
}

app-fab-icon-busy-button // <<<<<<<<<<<<<<<  FEATURE ACTION BUTTONS    - FAB!
{
	color: white;
	z-index: 50;
	overflow: hidden;
	//width: 72px;
}

@keyframes roll {
	from {
		transform: rotate(-180deg);
	}

	to {
		transform: rotate(0deg);
	}
}

@keyframes antiRoll {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(-180deg);
	}
}

@keyframes slideFadeIn {
	from {
		opacity: 0;
		transform: translateX(-100%);
	}

	to {
		opacity: 1;
		transform: translateX(0%);
	}
}

.rollIn {
	animation-name: roll;
	animation-delay: 1s;
	animation-duration: 0.5s;
}

.rollOpen {
	animation-name: antiRoll;
	animation-delay: 1s;
	animation-duration: 0.5s;
}

.rollInNoDelay {
	animation-name: roll;
	animation-duration: 0.5s;
}

.rollOpenNoDelay {
	animation-name: antiRoll;
	animation-duration: 0.5s;
}

.fabColumn {
	animation-delay: 1s;
	animation-duration: 0.5s;
	animation-name: slideFadeIn;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards; // Hold last frame
	padding-bottom: 16px;
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 100;
	opacity: 0;
}

.fabSpinner {
	width: 56px;
	height: 56px;
}

.miniFabSpinner {
	width: 40px;
	height: 40px;
}

.mat-fab // Default shadow messes up on firefox etc..
{
	position: relative;
	-webkit-box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2) !important;
	-moz-box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2) !important;
	box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2) !important;
}

.buttonAndSpinnerContainer {
	// height: 56px;
	// width: 56px;
	padding: 8px;
	display: block;
}

.buttonAndSpinnerContainerMini {
	/*height: 40px;
      width: 40px;*/
	padding: 8px;
	display: block;
}

mat-checkbox {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
}

.snackBarAltColour // <<<<<<<<<<<<<<<<<   Element Styling >>>>>>>>>>>>>
{
	background-color: mat-color($mat-indigo, 500) !important;
}

.shadow {
	// Generic shadow - tight.
	position: relative;
	-webkit-box-shadow: 0 4px 3px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 4px 3px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0 4px 3px 0px rgba(0, 0, 0, 0.3);
	border-bottom: 0px !important;
}

.shadow-left {
	// Generic shadow - tight.
	-webkit-box-shadow: -4px 0 5px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: -4px 0 5px 0 rgba(0, 0, 0, 0.2);
	box-shadow: -4px 0 5px 0 rgba(0, 0, 0, 0.2);
	border-bottom: 0px !important;
}

.dragStart {
	background-color: whitesmoke;
	opacity: 0.4;
}

.dropZone {
	border: 1px dashed #6074e24f !important;
	border-radius: 8px !important;
	background: rgb(249, 252, 255);
}

.dragOver {
	color: orange;
	border-style: solid;
	opacity: 1;
}

// Styles for preview of dragged elements

.cdk-drag-preview .mat-list-item-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	padding: 0 16px;
	position: relative;
	height: inherit;
}

.cdk-drag-preview .mat-list-item-content .mat-list-text {
	padding-right: 0;
	padding-left: 16px;
}

.clickable {
	cursor: pointer;
}

.fill-remaining-space {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

.mat-tab-header {
	border-bottom: 0px; // padding-left: 30px;
	padding-right: 30px;
}

.mat-toolbar,
.mat-toolbar-row {
	height: fit-content !important;
	min-height: 48px !important;
}

.mat-ink-bar {
	height: 3px !important;
	z-index: 100;
}

#level2-navigation .mat-ink-bar {
	height: 3px !important;
	z-index: 100;
}

.group-filters .mat-ink-bar {
	height: 3px !important;
	z-index: 100;
}

.slim-ink-bar .mat-ink-bar {
	height: 3px !important;
	z-index: 100;
}

.ink-bar-background {
	width: 100%;
	position: relative;
	height: 8px;
	top: -8px;
}

form.searchForm {
	flex: 1;
	//margin-right: 15px;
}

input:-webkit-autofill {
	box-shadow: 0 0 0px 1000px #f3f6f9 inset;
}

mat-progress-spinner,
/** Had to put this in , as material now seems to set spinners to 100px if not set ??? **/

mat-progress-spinner svg {
	height: 100%;
	width: 100%;
}

mat-progress-spinner.fabSpinner {
	// Needs containing take size of fab.
	float: right;
	height: 100%;
}

mat-progress-spinner.buttonSpinner {
	// Needs contiaing takes size of button.
	height: 100%;
}

mat-progress-spinner.busySpinner // Same size as large icon.
{
	padding: 2px;
	height: 100%;
}

mat-divider.dialogDivider {
	margin-left: -24px;
	margin-right: -24px;
}

.colour-grey {
	color: #d3d3d3 !important;
}

.svg-accent-button {
	cursor: pointer;
	stroke: #909090;
	stroke-width: 2;
}

:hover.svg-accent-button {
	stroke: #ffffff;
	stroke-width: 2.5;
}

.svg-accent-button-icon {
	stroke: #ffffff;
	fill: #ffffff;
	stroke-width: 2;
}

.svg-no-border {
	stroke-width: 0;
	border: none;
}

.svg-warning-icon {
	stroke: rgb(0, 0, 0);
	fill: rgb(255, 230, 0);
	stroke-width: 1.5;
}

.svg-delete-button {
	cursor: pointer;
	stroke: #909090;
	stroke-width: 2;
	fill: whitesmoke;
}

:hover.svg-delete-button {
	fill: #ef5350;
	stroke: whitesmoke;
	stroke-width: 2.5;
}

.svg-un-selected-button {
	cursor: pointer;
	fill: whitesmoke;
	stroke: #909090;
	stroke-width: 2;
}

:hover.svg-un-selected-button {
	fill: #fafafa;
	stroke: #909090;
	stroke-width: 2.5;
}

.export-state-distributed-preview {
	color: #fb8c00;
}

.enabled-colour {
	//color: #43A047;
	color: #6074e2;
}

.warn-colour {
	color: #fb63a8;
}

.disabled-colour {
	color: lightgray; // color: #FB8C00;
}

.export-state-distributed-latest {
	//color: #43A047;
	color: rgb(129, 199, 132) !important;
}

.export-state-error {
	color: #f00d60 !important;
}

.export-state-updating {
	color: lightgray !important;
}

.export-state-created {
	color: #fb8c00 !important;
}

.export-state-preview {
	color: #7986cb !important;
}

.export-state-limited-release {
	// color: #43A047;
	color: rgb(129, 199, 132);
}

.export-state-internal {
	color: #7986cb !important;
}

.export-state-unknown {
	color: #909090;
}

.login-button-bar {
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
}

.hoverFlash:hover {
	opacity: 1;
}

.hoverFlash {
	opacity: 0.5;
}

.fb-width-280 // <<<<<<<<<<<<<< COMMON FLEX BOX sizing.
{
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 280px;
	max-width: 280px;
}

.fb-width-350 // <<<<<<<<<<<<<< COMMON FLEX BOX sizing.
{
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 350px;
	max-width: 350px;
}

.fb-width-500 {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 500px;
	max-width: 500px;
}

.border-left {
	border-left: 1px solid lightgrey;
}

.width-160 {
	width: 160px;
}

.bottom-border {
	border-bottom: 1px solid lightgrey;
}

.top-border {
	border-top: 1px solid lightgrey;
}

.mat-nav-list .mat-chip-list-wrapper {
	align-items: center;
	flex-wrap: nowrap;
}

.chain-chip-list-item .mat-chip-list-wrapper {
	align-items: center;
}

.under-header-toggle-spacing {
	padding: 8px 4px 8px 22px;
}

.mat-icon-button {
	overflow: hidden;
}

.stroke-thin {
	stroke-width: 3;
}

.left-drawer-shadow {
	-webkit-box-shadow: 0 8px 10px 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 8px 10px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0 8px 10px 5px rgba(0, 0, 0, 0.2);
}

.right-drawer-shadow {
	-webkit-box-shadow: 0px -8px 10px 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px -8px 10px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0px -8px 10px 5px rgba(0, 0, 0, 0.2);
}

.temp-border {
	border: 1px solid yellowgreen;
}

@mixin callout {
	position: relative;
	/* height: 46px;*/
	margin: 4px 10px 4px 4px;
	padding-top: 9px;
	padding-bottom: 8px;
	padding-left: 16px;
	padding-right: 8px;
	background-color: whitesmoke;
	/* easy rounded corners for modern browsers */
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	overflow: visible;
}

/* When in a card the callout needs a white background */

mat-card .callout-left {
	background-color: white;
}

.callout-left {
	@include callout;
	width: 70%;
}

.callout-above-float {
	@include callout;
	width: 90%;
	bottom: 70px;
	position: absolute;
	left: 0px;
}

.callout-above {
	@include callout;
	width: 90%;
	/*
      left: 77px;
      bottom: 70px;
      */
}

@mixin notch {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	overflow: visible;
}

/* When in a card the callout needs a white background */

mat-card .notch-right {
	border-left: 10px solid white;
}

.notch-right {
	@include notch;
	right: -9px;
	top: 20px;
	border-top: 10px solid transparent;
	border-left: 10px solid whitesmoke;
	border-right: 0px;
	border-bottom: 10px solid transparent;
}

.notch-bottom {
	@include notch;
	bottom: -10px;
	right: 20px;
	border-top: 10px solid whitesmoke;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 0px;
}

.callout-left mat-error,
.callout-above mat-error,
.callout-above-float mat-error {
	margin-top: 10px;
	overflow: visible;
	font-size: 14px;
	font-style: italic;
	text-align: right;
}

.border-callout {
	padding-top: 8px;
	padding-bottom: 7px;
	padding-left: 15px;
	padding-right: 7px;
}

.border-callout .border-notch-right {
	right: -10px;
}

.border-callout .border-notch-bottom {
	bottom: -11px;
}

.id-tool-tip {
	background-color: #d3d3d3 !important;
}

.userProfileDialog .mat-dialog-container {
	padding: 0 0 24px 0;
}

.userProfileDialog .mat-dialog-actions {
	margin-top: 0;
	padding-right: 12px;
}

app-transfer-log-item {
	width: 100%;
}

.transferStatusList .mat-list-item-content {
	padding: 0px 0px !important;
}

.centerVertically {
	display: flex;
	align-items: center;
}

.greyBox {
	box-sizing: border-box;
	border-radius: 4px !important;

	legend {
		background-color: transparent;
	}
}

.greyBox-drop {
	border-radius: 4px !important;
	border: 2px dashed lightGrey;
	box-sizing: border-box;
}

.greyBox-no-drop {
	border: 2px solid lightGrey;
	box-sizing: border-box;
}

fieldset.greyChip {
	border: 2px #e0e0e0;
	background-color: #e0e0e0;
	box-sizing: border-box;
	border-radius: 24px;
}

.multilineTooltip {
	white-space: pre-line;
}

.multilineTooltipWithTabs {
	white-space: pre;
}

app-exporter-delivery-target-list-item {
	flex: 1;
}

.mat-form-field.noPadding .mat-form-field-wrapper {
	padding-bottom: unset;
}

// Used on the joining key input on the welcome home page ****************
.mat-form-field-appearance-outline .mat-form-field-infix .mat-input-element {
	position: relative;
	bottom: 0.14em;
}

.mat-form-field-appearance-outline
	.mat-form-field-wrapper
	.mat-form-field-flex {
	align-items: center !important;
}

//* Used to override default ag-grid behavior, allowing popup editors to appear above floating (FAB) buttons */
.ag-theme-balham .ag-popup-editor {
	z-index: 101 !important;
}

//* Used to correct row alignment issue when scrolled to bottom with a pinned left column
.ag-theme-balham .ag-pinned-left-cols-viewport-wrapper {
	height: calc(100% - 17px) !important;
}

.ag-theme-balham .ag-row-hover {
	/* putting in !important so it overrides the theme's styling as it hovers the row also */
	background-color: #e8edf4 !important;
}

.tiny-fab {
	height: 20px !important;
	width: 20px !important;
	line-height: 20px !important;
	margin: 0px 4px !important;
	box-shadow: none !important;
}

//***********************************************

/******************** CSS VARIABLES********************/
:root {
	--exportVersionStateBallSize: 18px;
}

.aview-tab-group .mat-tab-body-content {
	overflow: hidden !important;
}

.overflow-y-auto {
	overflow-y: auto;
}

.h-full {
	height: 100%;
}
