/* All css content in styles.scss */

.goog-te-banner-frame.skiptranslate {
	display: none !important;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	top: 0px !important;
}

html,
body {
	height: 100%;
}
